import React from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./tools/ScrollToTop";
import Nav from "./pages/Navbar.js";
import Home from "./pages/Home.js";
import About from "./pages/About.js";
import Services from "./pages/Services.js";
import Contact from "./pages/Contact.js";
import Footer from "./pages/Footer.js";

function App() {
  return (
    <>
      <div className="">
        <ScrollToTop />
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default App;
