import React from "react";
import { Link } from "react-router-dom";
// import logo from "../assets/PNG Logo Files/Original Logo.png";

const HomePage = () => {
  return (
    <>
      <div className="bg-cream">
        <div className="justify-center bg-cream grid grid-cols-1 lg:grid-cols-2 pt-10">
          <section className="flex-col items-center text-dark-green lg:pl-10 lg:h-96 lg:py-0">
            <h1 className="text-5xl font-bold text-center mb-6">
              Elevated Home Comfort
            </h1>
            <p className="text-center text-lg mb-10">
              Experience the finest home comfort with our expert HVAC systems
              and services.
            </p>

            <div className="flex items-center justify-center font-pro">
              <input
                type="text"
                placeholder="ZIP Code"
                className=" border-dark-green rounded-l-full py-2 px-4 w-64 outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <button className="bg-yellow hover:bg-orange font-bold py-2 px-4 rounded-r-full text-dark-green">
                Get Quote
              </button>
            </div>
            <div className="flex flex-col mx-auto bg-cream p-6 rounded-lg text-center items-center">
              <h2 className="text-xl font-bold text-gray-700 mb-4 text-center">
                Get Accurate Quotes for Your Rocky Mountain HVAC Project
              </h2>
              <div className="flex flex-col lg:flex-row">
                <div className="bg-light-blue px-2 py-2 my-2 rounded-lg lg:w-1/2 mx-2">
                  <h3 className="text font-bold text-gray-700 mb-2">
                    Compare Bids from Local HVAC Contractors.
                  </h3>
                </div>
                <div className="bg-light-blue px-2 py-2 my-2 rounded-lg lg:w-1/2 mx-2">
                  <h3 className="text font-bold text-gray-700 mb-2">
                    Our Service is 100% Free!
                  </h3>
                </div>
                <div className="bg-light-blue px-2 py-2 my-2 rounded-lg lg:w-1/2 mx-2">
                  <h3 className="text font-bold text-gray-700 mb-2">
                    No Obligations, Ever!
                  </h3>
                </div>
              </div>
            </div>
          </section>

          <section className="flex-col items-center text-dark-green pl-10 pr-5">
            <div className=" flex-row mb-6 lg:mt-0 mt-5">
              <h1 className="text-5xl font-bold text-center mb-6">
                Most Popular Services
              </h1>
            </div>
            <div className="grid grid-cols-2">
              <button className="bg-white rounded-md shadow-lg p-6 m-1 hover:bg-orange duration-300 ease-in-out">
                {" "}
                <Link
                  to={"/services"}
                  className="btn btn-primary block text-center font-bold text-xl mb-3"
                >
                  Install or Replace
                </Link>
              </button>
              <div className="bg-white rounded-md shadow-lg p-6 m-1 hover:bg-orange duration-300 ease-in-out">
                <Link
                  to={"/services"}
                  className="btn btn-primary block text-center font-bold text-xl mb-3"
                >
                  Central A/C
                </Link>
              </div>
              <div className="bg-white rounded-md shadow-lg p-6 m-1 hover:bg-orange duration-300 ease-in-out">
                <Link
                  to={"/services"}
                  className="btn btn-primary block text-center font-bold text-xl mb-3"
                >
                  Clean Air Ducts and Vents A/C
                </Link>
              </div>
              <div className="bg-white rounded-md shadow-lg p-6 m-1 hover:bg-orange duration-300 ease-in-out">
                <Link
                  to={"/services"}
                  className="btn btn-primary block text-center font-bold text-xl mb-3"
                >
                  Install or Replace Furnace
                </Link>
              </div>
              <div className="bg-white rounded-md shadow-lg p-6 m-1 hover:bg-orange duration-300 ease-in-out">
                <Link
                  to={"/services"}
                  className="btn btn-primary block text-center font-bold text-xl mb-3"
                >
                  Install or Replace Air Ducts
                </Link>
              </div>
              <div className="bg-white rounded-md shadow-lg p-6 m-1 hover:bg-orange duration-300 ease-in-out">
                <Link
                  to={"/services"}
                  className="btn btn-primary block text-center font-bold text-xl mb-3"
                >
                  Repair Heating Furnace Repair Central
                </Link>
              </div>
            </div>
            {/* <Link
            className="bg-yellow text-dark-green py-2 px-6 rounded-full hover:bg-orange transition-all duration-300 ease-in-out"
            to="/services"
          >
            Our Services
          </Link> */}
          </section>
        </div>

        <section className="mt-20">
          <div className="mx-auto bg-cream p-6 rounded-lg shadow-lg">
            <h2 className="text-4xl font-bold text-gray-700 mb-4 text-center">
              Get Accurate Quotes for Your Rocky Mountain HVAC Project
            </h2>
            <div className="bg-light-blue px-2 py-2 my-2 rounded-lg">
              <h3 className="text-2xl font-bold text-gray-700 mb-2">
                Compare Bids from Local HVAC Contractors.
              </h3>
              <p className="text-gray-600 mb-6 text-xl">
                Our service helps you find up to four HVAC pros who specialize
                in your Rocky Mountain project type. You can compare quotes side
                by side within 24 hours, without the hassle of calling multiple
                HVAC pros yourself.
              </p>
            </div>
            <div className="bg-light-blue px-2 py-2 my-2 rounded-lg">
              <h3 className="text-2xl font-bold text-gray-700 mb-2">
                Our Service is 100% Free!
              </h3>
              <p className="text-gray-600 mb-6 text-xl">
                We don't ask for any financial information and you can use our
                service as many times as you like. Plus, there are no hidden
                fees or charges.
              </p>
            </div>
            <div className="bg-light-blue px-2 py-2 my-2 rounded-lg mb-10">
              <h3 className="text-2xl font-bold text-gray-700 mb-2">
                No Obligations, Ever!
              </h3>
              <p className="text-gray-600 mb-6 text-xl">
                We find you qualified HVAC pros that best meet the needs of your
                Rocky Mountain project, but if you're not fully satisfied with
                the quotes provided, you are not obligated to move forward.
              </p>
            </div>
          </div>
        </section>

        <section className="w-full px-6 pb-48 pt-24 bg-orange">
          <h2 className="text-3xl text-center font-bold text-gray-700 mb-10">
            Why Choose Us?
          </h2>
          <div className="flex flex-col sm:flex-row items-center">
            <div className="bg-white p-4 rounded-md shadow-lg mb-10 sm:mb-0 sm:w-1/3 m-4">
              <h3 className="text-xl font-bold text-gray-700 mb-3">
                Expertise and Experience
              </h3>
              <p className="text-gray-600">
                Our team of experts has years of experience in the AC industry
                and can handle all your needs with ease.
              </p>
            </div>
            <div className="bg-white p-4 rounded-md shadow-lg mb-10 sm:mb-0 sm:w-1/3 m-4">
              <h3 className="text-xl font-bold text-gray-700 mb-3">
                Quality Service
              </h3>
              <p className="text-gray-600">
                We believe in providing our customers with the best service
                possible and strive to exceed their expectations every time.
              </p>
            </div>
            <div className="bg-white p-4 rounded-md shadow-lg mb-10 sm:mb-0 sm:w-1/3 m-4">
              <h3 className="text-xl font-bold text-gray-700 mb-3">
                Affordable Prices
              </h3>
              <p className="text-gray-600">
                We offer competitive pricing without sacrificing quality,
                ensuring that our services are accessible to everyone.
              </p>
            </div>
          </div>
        </section>

        <section className="w-full px-6 pb-24 mt-10 pt-10 bg-orange">
          <h2 className="text-3xl text-center font-bold text-gray-700 mb-10">
            Reviews
          </h2>
          <div className="flex flex-col sm:flex-row items-center">
            <div className="bg-white p-4 rounded-md shadow-lg mb-10 sm:mb-0 sm:w-1/3 m-4">
              <h3 className="text-xl font-bold text-gray-700 mb-3">Angi</h3>
              <p className="text-gray-600">
                Our team of experts has years of experience in the AC industry
                and can handle all your needs with ease.
              </p>
            </div>
            <div className="bg-white p-4 rounded-md shadow-lg mb-10 sm:mb-0 sm:w-1/3 m-4">
              <h3 className="text-xl font-bold text-gray-700 mb-3">
                Quality Service
              </h3>
              <p className="text-gray-600">
                We believe in providing our customers with the best service
                possible and strive to exceed their expectations every time.
              </p>
            </div>
            <div className="bg-white p-4 rounded-md shadow-lg mb-10 sm:mb-0 sm:w-1/3 m-4">
              <h3 className="text-xl font-bold text-gray-700 mb-3">
                Affordable Prices
              </h3>
              <p className="text-gray-600">
                We offer competitive pricing without sacrificing quality,
                ensuring that our services are accessible to everyone.
              </p>
            </div>
          </div>
          <div className="flex justify-center lg:justify-end pt-10">
            <img
              src="https://logosandtypes.com/wp-content/uploads/2021/04/Angi.png"
              alt="Angi Mountain Top HVAC"
              className="w-32 h-32 rounded-full p-2"
            />
            <img
              src="https://logowik.com/content/uploads/images/google-customer-reviews4282.jpg"
              alt="Google Reviews Mountain Top HVAC"
              className="w-32 h-32 rounded-full object-fit p-2"
            />
          </div>
        </section>

        <section className="w-full px-6 py-20">
          <h2 className="text-3xl font-bold text-gray-700 text-center mb-10">
            Our Services
          </h2>
          <div className="flex flex-wrap justify-between items-center">
            <div className="w-full md:w-1/2 lg:w-1/3 p-6 mb-10 md:mb-0">
              <div className="bg-white rounded-md shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-700 mb-3">
                  AC Installation
                </h3>
                <p className="text-gray-600">
                  We offer professional AC installation services for all types
                  of homes and businesses.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-6 mb-10 md:mb-0">
              <div className="bg-white rounded-md shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-700 mb-3">
                  AC Maintenance
                </h3>
                <p className="text-gray-600">
                  Regular AC maintenance is essential to keep your unit running
                  smoothly and efficiently.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-6 mb-10 md:mb-0">
              <div className="bg-white rounded-md shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-700 mb-3">
                  AC Repair
                </h3>
                <p className="text-gray-600">
                  If your AC unit is malfunctioning, our experts can diagnose
                  and repair the issue quickly and effectively.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-6 mb-10 md:mb-0">
              <div className="bg-white rounded-md shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-700 mb-3">
                  Duct Cleaning
                </h3>
                <p className="text-gray-600">
                  Dirty ducts can negatively impact indoor air quality and cause
                  health issues. Let us clean your ducts for you.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-6 mb-10 md:mb-0">
              <div className="bg-white rounded-md shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-700 mb-3">
                  24/7 Emergency Service
                </h3>
                <p className="text-gray-600">
                  We offer 24/7 emergency services to ensure that you are never
                  without AC when you need it most.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-6 mb-10 md:mb-0">
              <div className="bg-white rounded-md shadow-lg p-6">
                <h3 className="text-xl font-bold text-gray-700 mb-3">
                  Indoor Air Quality
                </h3>
                <p className="text-gray-600">
                  We offer a range of products and services to improve indoor
                  air quality, including air purifiers and humidifiers.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default HomePage;
