import React from "react";
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/PNG Logo Files/Transparent Logo.png";
// import logo from "../assets/SVG Vector Files/Transparent Logo.svg";

function Navbar(props) {
  return (
    <>
      <nav className="lg:flex py-3 bg-cream font-pro hidden border-b-2  animate-fade-in-down">
        <div className="w-full text-dark-green">
          <div className="flex-row">
            <Link to="/">
              <img
                src={logo}
                className="w-1/5 mx-auto animate-fade-in-down"
                alt="Mountain Top HVAC Logo"
              />
            </Link>
          </div>
          <div className="mx-auto max-w-4xl">
            <ul class="flex flex-row justify-evenly space-x-4">
              <li className="nav-item w-36 flex justify-center">
                <Link to={"/"}>
                  <span class="px-3 py-2 flex items-center text uppercase hover:opacity-75">
                    Home
                  </span>
                </Link>
              </li>

              <li className="nav-item w-36 flex justify-center">
                <Link to={"/about"}>
                  <span class="px-3 py-2 flex items-center text uppercase hover:opacity-75">
                    About
                  </span>
                </Link>
              </li>

              <li className="nav-item w-36 flex justify-center">
                <Link to={"/services"}>
                  <span class="px-3 py-2 flex items-center text uppercase hover:opacity-75">
                    Services
                  </span>
                </Link>
              </li>

              <li className="nav-item w-36 flex justify-center">
                <Link to={"/contact"}>
                  <span class="px-3 py-2 flex items-center text uppercase hover:opacity-75">
                    Contact Us
                  </span>
                </Link>
              </li>

              <li className="nav-item w-36 flex justify-center">
                <a
                  href="https://squareup.com/appointments/book/ubwzro1cx9enab/LCBJB4VBRS0Z4/services"
                  // target="_blank"
                  // rel="noopener noreferrer"
                >
                  <span className="px-3 py-2 flex items-center text uppercase hover:opacity-75 bg-yellow hover:bg-orange duration-300 ease-in-out rounded-full">
                    Book Now
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <nav className="lg:hidden bg-cream text-dark-green font-pro animate-fade-in-down">
        <div className="flex flex-row text-center text-2xl py-2">
          <Link to="/">
            {/* <div className="grid grid-cols-2"> */}
            <img
              src={logo}
              className="w-8/12 sm:w-6/12 md:4/12 scale-125 mx-auto animate-fade-in-down-scaled"
              alt="Mountain Top HVAC Logo"
            />
            {/* <h1 className="pt-1 text-2xl text-dark-green">Mountain Top HVAC</h1> */}
            {/* </div> */}
          </Link>
        </div>
        <div className="grid grid-cols-2 border-t-2 border-b-2">
          <a href="tel:7203416551">
            <div className="border-r-2 text-center py-2">720-341-6551</div>
          </a>
          <a href="https://squareup.com/appointments/book/ubwzro1cx9enab/LCBJB4VBRS0Z4/services">
            <div className="border-l-2 text-center py-2">Book Now</div>
          </a>
        </div>
      </nav>
    </>
  );
}
export default Navbar;
