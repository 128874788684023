import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      {" "}
      <footer className="w-full px-6 py-10 bg-dark-blue text-white flex flex-col sm:flex-row justify-between items-center">
        <div className="flex flex-col items-center mb-4 sm:mb-0 sm:flex-row">
          {/* <img src={logo} alt="AC Company Logo" className="w-10 h-10 mr-4" /> */}
          <p>Mountain Top Heating and Cooling</p>
        </div>
        <div className="flex flex-wrap justify-center">
          <button className="mx-2 text-white hover:text-gray-400 transition-all duration-300 ease-in-out">
            <Link to={"/"}>Home</Link>
          </button>
          <button className="mx-2 text-white hover:text-gray-400 transition-all duration-300 ease-in-out">
            <Link to={"/about"}>About</Link>
          </button>
          <button className="mx-2 text-white hover:text-gray-400 transition-all duration-300 ease-in-out">
            <Link to={"/services"}>Services</Link>
          </button>
          <button className="mx-2 text-white hover:text-gray-400 transition-all duration-300 ease-in-out">
            <Link to={"/contact"}>Contact</Link>
          </button>
        </div>
        <div>
          <p className="text-dark-green">
            &copy; 2023 Mountain Top Heating and Cooling
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
